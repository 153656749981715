// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.custom-cell {
  border: 1px solid;
  text-align: center;
  padding: 2px;
}
.bg-playlist {
  background-color: #56bd74 !important;
}
.side-cart {
  background: #fff;
  margin: 12px 3px;
}
.cart {
  background: #fff;
  margin: 2px;

  .table td {
    height: 48px;
    border: 1px solid #d6d6d6;
    position: relative;
    overflow: hidden;
    border-top: 0px solid red;
  }
  .table th {
    color: #989898;
    border-bottom: 0px solid red !important;
    border: none;
    padding-left: 0px;
    padding-bottom: 2px;
  }
  .table thead {
    position: relative;
  }
  .irow {
    background: #d0d0d0;
    width: 2px;
    margin-left: 5px;
    display: inline-block;
  }
  .row-box1 {
    position: absolute;
    bottom: -4px;
    left: 0px;
    width: 100%;
  }

  .left {
    left: -6px;
    top: 9px;
    position: absolute;
    color: #bdb8b8;
  }
  .left i {
    cursor: pointer;
  }
  .right {
    right: 0px;
    top: 8px;
    position: absolute;
    color: #bdb8b8;
  }
  .right i {
    cursor: pointer;
  }
  .pd-0 {
    padding: 0px;
  }
  .overlay {
    border: 0px solid #f00;
    position: absolute;
    height: 45%;
    bottom: 0;
    left: 0;
    background: #0006;
  }
  .primary {
    background: #0275d880;
  }
  .base {
    border: 2px solid #9a6161;
    position: absolute;
    bottom: 0px;
    height: 100%;
  }
  .base-primary {
    border: 2px solid #0275d8;
    position: absolute;
    bottom: 0px;
    height: 100%;
  }
  .success {
    background: #5cb85c99;
  }
  .base-success {
    border: 2px solid #5cb85c;
    position: absolute;
    bottom: 0px;
    height: 100%;
  }
}
// /* Smartphones (portrait and landscape) ----------- */
// @media only screen
// and (min-device-width : 320px)
// and (max-device-width : 480px) {
//   .side-cart {
//     display: none;
//   }
// }

// /* Smartphones (landscape) ----------- */
// @media only screen
// and (min-width : 321px) {
//   .side-cart {
//     display: none;
//   }
// }

// /* Smartphones (portrait) ----------- */
// @media only screen
// and (max-width : 320px) {
//   .side-cart {
//     display: none;
//   }
// }

// Extra small devices (phones, 600px and down) /
@media only screen and (max-width: 600px) {
  // .side-cart {
  //   display: none;
  // }
}

// Small devices (portrait tablets and large phones, 600px and up) /
@media only screen and (min-width: 600px) {
}

// Medium devices (landscape tablets, 768px and up) /
@media only screen and (min-width: 768px) {
}

// Large devices (laptops/desktops, 992px and up) /
@media only screen and (min-width: 992px) {
}

// Extra large devices (large laptops and desktops, 1200px and up) /
@media only screen and (min-width: 1200px) {
}

.playlist {
  .row {
    margin: 0px;
    padding: 0px;
  }

  .mr-0 {
    margin: 0px;
  }
  .pd-0 {
    padding: 0px;
  }
  .view-box {
    border: 1px solid #b5b5b5;
  }
  .view-title {
    border: 0px solid green;
    background: #465a65;
    color: #fff;
    padding: 15px;
  }
  .view-title-playlist {
    padding: 15px;
  }
 
  .right-view-box {
    border: 1px solid #b5b5b5;

    margin: 0px 3px;
  }

  .btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
    background: #f4233c;
    color: #fff;
  }
  .container-box {
    padding: 0px 10px;
  }
  .space {
    margin: 13px 0px;
  }

  .search-bar > input[type="text"] {
    width: 200px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 0px;
    font-size: 16px;
    background-color: white;
    background-image: url("../assets/img/iconfinder_icon4.png");
    background-position: 10px 2px;
    background-repeat: no-repeat;
    padding: 6px 20px 12px 40px;
  }
}
.c-w-h {
  list-style: none;
  padding: 0px;
  margin: 0px ;
  display: inline-flex;
  justify-content: center;
  align-items: CENTER;
}
.c-w-h li {
  margin: 0px 0px 0px 0px;
}
.campaign-type {
  box-shadow: 0px 4px 9px 1px grey;
  text-align: center;
}
@keyframes example {
  0%   {width: 60%;}
  100% {width: 70%;}
}
.campaign-type:hover{

  border: 1px solid #81819f;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.2s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.2s;
}
.selected-campaign-type {
  width: 70%;
}


.overflow-x-auto {
  overflow-x: auto;
}

.btm-divider {
  border-bottom: 1px solid rgb(224, 221, 221);
  margin-bottom: 1rem;
}