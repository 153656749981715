// Here you can add other styles
.fa-6 {
  font-size: 15em;
}

.fa-4 {
  font-size: 10em;
}
.custom-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 150%;
  font-weight: 200;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #63c2de;
}
.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #c8ced3;
  padding: 20px;
}
.dotted-border {
  border: thin dotted green;
}
@font-face {
  font-family: bodyFont;
  src: url("../fonts/Ubuntu-R.ttf");
}
@font-face {
  font-family: navItem;
  src: url("../fonts/Ubuntu-B.ttf");
}
.nav-item {
  font-family: navItem;
}
body {
  font-family: bodyFont;
}

INPUT[type=checkbox]:focus
{
    outline: 1px solid rgba(0, 0, 0, 0.2);
}

INPUT[type=checkbox]
{
    background-color: #DDD;
    border-radius: 2px;
    border: 1px solid #7e8ec8;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 5px;
}

INPUT[type=checkbox]:checked
{
    background-color: #409fd6;
    background: #409fd6 url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat;
}
.form-control{
  border: 2px solid #e4e7ea;
}
.capitalize{
  text-transform: capitalize;
}
.input-error{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}

.taxi{
  .create{
    legend{
      color:#4dbd74 !important
    }
    .react-datepicker-wrapper {
      width: calc(100% - 2.5rem);
    }
    .react-auto-complete{
      width: calc(100% - 41px);
      input{
        width:95%
      }
    }
    .profile-image{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
} 

.pointer{
  cursor: pointer  !important;
}

.heat-map{
  position: relative !important;
}